/* eslint-disable react-hooks/exhaustive-deps */
import { CCard, CCardBody, CCardHeader} from "@coreui/react";
import { useLocation} from "react-router-dom";
import { Helmet } from "react-helmet";
import AInfraGrid from "../../../Grid/Common_Components/AInfraGrid";
import { aquaJobHistoryHeaderNames, aquaJobHistoryNumberKeys } from "../../../Grid/Common_Components/headerFieldNames";
import { getRandomAlphaNumericString } from "../../../Grid/Common_Components/grid-functions";

export const JobHistory = () => {
    const location = useLocation();

    const pagesizes = [
        { label: "10", value: "10" },
        { label: "20", value: "20" },
        { label: "50", value: "50" },
        { label: "All", value: 0 },
      ];

      const aquaJobHistoryHeaderMappins =[
        { name: "History ID", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Job Status", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Account Code", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Database Name", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Response Type", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Created By", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Start Date", dataType: "DateTime", width: "auto", minWidth: "0px" },
        { name: "Completed Date", dataType: "DateTime", width: "auto", minWidth: "0px" },
        { name: "Purge Date", dataType: "DateTime", width: "auto", minWidth: "0px" },
        { name: "Job ID", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Client Request ID", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Client ID", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Job Status Text", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Job Run ID", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Percent Completed", dataType: "Number", width: "auto", minWidth: "0px" , decimal: true},
        { name: "Modified By", dataType: "String", width: "auto", minWidth: "0px" },
        { name: "Created Date", dataType: "DateTime", width: "auto", minWidth: "0px" },
        { name: "Modified Date", dataType: "DateTime", width: "auto", minWidth: "0px" },
      ]

      let filter =[
        {
            "id": getRandomAlphaNumericString(10),
            "key": "DefaultLeftKey",
            "value": "(",
            "operator": "",
            "condition": "",
            "isExpSelected": false,
            "type": "Group",
            "isReadable": false
        },
        {
            "id": getRandomAlphaNumericString(10),
            "key": "JobID",
            "value": "{JobID}",
            "operator": "eq",
            "condition": "",
            "isExpSelected": false,
            "type": "Expression",
            "isReadable": true
        },
        {
            "id": getRandomAlphaNumericString(10),
            "key": "DefaultRightKey",
            "value": ")",
            "operator": "",
            "condition": "",
            "isExpSelected": false,
            "type": "Group",
            "isReadable": false
        }
    ]

    const getFilterQueryAquaHistory=[
        {label: '{JobID}',value: location?.jobId || localStorage.getItem("jobId") },
      ]


    return (
        <>
         <Helmet>
                <title>AIMS360 - Reports AquaScheduler JobList History</title>
            </Helmet>

            <CCard >
                <CCardHeader>Job Run History</CCardHeader>
                <CCardBody className='px-0 pt-0'>
                <AInfraGrid
                    ModuleMenuCode="AIM360AQJBHSH01"
                    filteringNumberAndGuidKeys={aquaJobHistoryNumberKeys}
                    pagesizes={pagesizes}
                    HeaderKeyMappings={aquaJobHistoryHeaderNames}
                    viewsWithTabs={false}
                    searchPlaceHolder="Job Status, Database Name"
                    DefaultViewName="Job Run History"
                    viewSubcategory='Aqua Scheduler'
                    DefaultCategory="Reports"
                    DefaultFilters={JSON.stringify(filter)}
                    gridPrimaryKey="Job Run ID"
                    gridColumns={aquaJobHistoryHeaderMappins}
                    DynamicFilters={getFilterQueryAquaHistory}
                    activeViewName="Job Run History"
                    urlEndPoint="/AquaJobHistories"
                    searchFilter={"contains(JobStatus, '{search}') or contains(DatabaseName, '{search}')"}
                    selectRowOnClick={false}
                    isPageSize={false}   
                    filterOperatorsNumberKeysOnly={["History ID","Percent Completed"]}     
                />
                </CCardBody>
            </CCard>           
        </>
    )
}
